import React, { useEffect } from 'react';
import { NextSeo } from 'next-seo';
import absoluteUrl from 'next-absolute-url';
import OpenApi from 'api/openApi';
import Container from '@material-ui/core/Container';

import Layout from 'components/Layout';
import BoxAnuncie from 'components/BoxAnuncie';
import BannerCapa from 'components/BannerCapa';
import GPTComponent from '../components/gpt/gpt';
import MapCards from 'components/Cards/Mapping';

import { makeStyles } from '@material-ui/core/styles';

import { useKeycloak } from '@react-keycloak/nextjs';

const containerStyles = makeStyles(() => ({
    root: {
        padding: '10px',
    },
}));

const Home = ({ filtros, canonicalUrl, lancamento, referrer }) => {
    const containerClasses = containerStyles();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        const parsedToken = keycloak?.tokenParsed;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <NextSeo
                title="Casas, apartamentos e imóveis para alugar ou à venda - Classi"
                canonical={canonicalUrl}
                description="Comprar, vender ou alugar é no Classificados mais tradicional de Goiás. Encontre casas, apartamentos, terrenos, imóveis para venda, compra ou aluguel."
                openGraph={{
                    title: 'Casas, apartamentos e imóveis para alugar ou à venda - Classi',
                    description:
                        'Comprar, vender ou alugar é no Classificados mais tradicional de Goiás. Encontre casas, apartamentos, terrenos, imóveis para venda, compra ou aluguel.',
                }}
            />
            <main style={{ backgroundColor: '#f0f2f5' }}>
                <Container className={containerClasses.root} maxWidth="md">
                    <div
                        style={{
                            marginTop: '10px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <GPTComponent gpt="small_maxiboard" />
                    </div>

                    <BannerCapa filtros={filtros} />

                    <MapCards
                        lancamentos={lancamento}
                        qtdCards={lancamento.length}
                        referrer={referrer}
                    />
                </Container>

                <BoxAnuncie />
            </main>
        </Layout>
    );
};

export async function getServerSideProps(ctx) {
    const { res } = ctx;
    const { getLancamento, filtro } = OpenApi();
    const { origin } = absoluteUrl(ctx.req);

    let props = {
        filtros: {},
        canonicalUrl: `${origin}${ctx.resolvedUrl}`,
        lancamento: [],
        categorias: [],
    };

    res.setHeader('Cache-Control', 'no-store');

    await getLancamento().then((resp) => {
        props = { ...props, lancamento: resp };
    });

    await filtro({ secao: 'IMOVEL_VENDA' }).then((response) => {
        props.filtros = response;
    });

    return {
        props: {
            ...props,
            referrer: ctx.req.headers?.referer || null,
        },
    };
}

export default Home;
