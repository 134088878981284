import React, { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import OpenApi from 'api/openApi';

import {
    BoxButtonBuscar,
    BoxBuscaContainer,
    BoxComprarAlugar,
} from './BoxBusca-styled';

import { Form, Field } from 'react-final-form';
import { debounce } from 'lodash';

import Grid from '@material-ui/core/Grid';

import Button from '@gjc/classi-react-commons/ClassiButton';
import Autocomplete from '@gjc/classi-react-commons/Autocomplete';

import { StringUtils } from 'utils/StringUtils';
import { formatLocationGroupList } from 'utils/methods/formatLists';


const BoxBusca = ({ filtros: filtrosSSP }) => {
    const { filtro, localizacao: getLocalizacao } = OpenApi();
    const router = useRouter();

    const [tipoBusca, setTipoBusca] = useState('IMOVEL_VENDA');
    const [filtros, setFiltros] = useState(filtrosSSP);

    const [localizacao, setLocalizacao] = useState([]);

    const onSubmit = async (form) => {
        const { localizacao } = form;

        const splitedUf = localizacao?.descricao.split(' - ');
        const uf =
            splitedUf?.length > 1
                ? StringUtils.cleanString(splitedUf[1])
                : splitedUf
                ? StringUtils.cleanString(splitedUf[0])
                : '';
        const cidade = StringUtils.cleanString(
            form?.localizacao?.descricao.split(' - ')?.[0].split(', ')?.[1]
        );
        const ufCidade = uf && cidade ? `${uf}-${cidade}` : uf ? uf : '';
        const bairro =
            localizacao?.descricao.split(', ').length > 1
                ? StringUtils.cleanString(localizacao.descricao.split(', ')[0])
                : '';

        const categoria = tipoBusca === 'IMOVEL_VENDA' ? 'comprar' : 'alugar';
        const tipoImovel = form?.categoria
            ? `${StringUtils.cleanString(
                  form?.categoria?.descricao
              )}-${StringUtils.cleanString(form?.categoria?.agrupamento)}`
            : '';

        const query = '?pagina=0';

        const urlMontado = `${process.env.BASE_URL}/${categoria}${
            tipoImovel && '/' + tipoImovel
        }${
            ufCidade && !tipoImovel
                ? '/todos/' + ufCidade
                : ufCidade
                ? '/' + ufCidade
                : ''
        }${bairro && '/' + bairro}${query}`;

        await router.push(urlMontado);
    };

    const handleGetFiltros = async (secao) => {
        await filtro({ secao }).then((response) => {
            setFiltros(response);
        });
    };

    const loadSuggestedOptions = useCallback(
        (e) =>
            debounce((e) => {
                if (e.target.value?.length >= 2) {
                    getLocalizacao({
                        search: `"${e.target.value}"`,
                        secao: tipoBusca,
                    }).then((options) => {
                        const newOptions = formatLocationGroupList(options);
                        // console.log(newOptions);
                        setLocalizacao(newOptions);
                    });
                }
            }, 500)(e),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getLocalizacao]
    );

    const formatCharacteristics = (list) => {
        return list?.reduce(
            (acc, cur) =>
                acc.concat(
                    cur.options.map((item) => ({
                        ...item,
                        agrupamento: cur.descricao,
                    }))
                ),
            []
        );
    };

    return (
        <BoxBuscaContainer>
            <Form
                mutators={{}}
                onSubmit={onSubmit}
                render={({ handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <BoxComprarAlugar>
                                <Button
                                    label={'COMPRAR'}
                                    appearance={'primary'}
                                    type={'button'}
                                    onClick={() => {
                                        setTipoBusca('IMOVEL_VENDA');
                                        handleGetFiltros('IMOVEL_VENDA');
                                        values.categoria = undefined;
                                    }}
                                    style={
                                        tipoBusca === 'IMOVEL_VENDA'
                                            ? {
                                                  backgroundColor: '#288394',
                                                  marginLeft: 0,
                                                  marginRight: 3,
                                                  border: 'none',
                                                  fontWeight: '500',
                                                  padding: '0',
                                                  boxShadow: 'none !important',
                                                  borderRadius: 3,
                                              }
                                            : {
                                                  backgroundColor: '#FFF',
                                                  marginLeft: 0,
                                                  marginRight: 3,
                                                  border: 'solid 1px #288394',
                                                  fontWeight: '500',
                                                  padding: '0',
                                                  boxShadow: 'none !important',
                                                  borderRadius: 3,
                                                  color: '#288394',
                                              }
                                    }
                                />
                                <Button
                                    label={'ALUGAR'}
                                    appearance={'primary'}
                                    type={'button'}
                                    onClick={() => {
                                        setTipoBusca('IMOVEL_ALUGUEL');
                                        handleGetFiltros('IMOVEL_ALUGUEL');
                                        values.categoria = undefined;
                                    }}
                                    style={
                                        tipoBusca === 'IMOVEL_ALUGUEL'
                                            ? {
                                                  backgroundColor: '#288394',
                                                  marginLeft: 3,
                                                  marginRight: 0,
                                                  border: 'solid 1px #288394',
                                                  fontWeight: '500',
                                                  padding: '0',
                                                  boxShadow: 'none !important',
                                                  borderRadius: 4,
                                              }
                                            : {
                                                  backgroundColor: '#FFF',
                                                  marginLeft: 3,
                                                  marginRight: 0,
                                                  border: 'solid 1px #288394',
                                                  fontWeight: '500',
                                                  padding: '0',
                                                  boxShadow: 'none !important',
                                                  borderRadius: 4,
                                                  color: '#288394',
                                              }
                                    }
                                />
                            </BoxComprarAlugar>

                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Field name="categoria">
                                        {({ input }) => (
                                            <Autocomplete
                                                label="Tipo do imóvel"
                                                options={formatCharacteristics(
                                                    filtros.categorias
                                                )}
                                                noOptionsText="Nenhum tipo de imóvel encontrado"
                                                groupBy={(option) =>
                                                    option.agrupamento
                                                }
                                                getOptionLabel={(option) =>
                                                    option.descricao
                                                }
                                                getOptionsSelected={(
                                                    option,
                                                    value
                                                ) => option.valor === value}
                                                onChange={(_, local) =>
                                                    input.onChange(local)
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item>
                                    <Field name="localizacao">
                                        {({ input }) => (
                                            <Autocomplete
                                                label="Digite um bairro, cidade ou estado."
                                                onInputChange={
                                                    loadSuggestedOptions
                                                }
                                                options={localizacao}
                                                noOptionsText="Digite a localização"
                                                onClose={() =>
                                                    setLocalizacao([])
                                                }
                                                groupBy={(option) =>
                                                    option.agrupamento
                                                }
                                                getOptionLabel={(option) =>
                                                    option.descricao
                                                }
                                                getOptionsSelected={(
                                                    option,
                                                    value
                                                ) => option.valor === value}
                                                onChange={(_, local) =>
                                                    input.onChange(local)
                                                }
                                                filterOptions={() =>
                                                    localizacao
                                                }
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <BoxButtonBuscar>
                                <Button
                                    label={'BUSCAR'}
                                    appearance={'primary'}
                                    type={'submit'}
                                    style={{
                                        fontWeight: 'normal',
                                        marginLeft: 0,
                                        marginRight: 0,
                                        backgroundColor: '#ffa000',
                                        border: 'none',
                                    }}
                                />
                            </BoxButtonBuscar>
                        </form>
                    );
                }}
            />
        </BoxBuscaContainer>
    );
};

export default BoxBusca;
